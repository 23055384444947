import React from "react";

import { PageContainer } from "../pageContainer/PageContainer";
import CalendarComponent from "../../components/calendar/Calendar";
import {  DateRangeProvider } from "../../context/DateRangeContext";

const CalendarPage = () => {
  return (
    <DateRangeProvider>
    <PageContainer mobileNoPadding={true}>
      <CalendarComponent />
    </PageContainer>
    </DateRangeProvider>
  );
};

export default CalendarPage;
