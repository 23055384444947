/* eslint-disable  react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { TextField } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import UniversalModal from "../../modal/UniversalModal";
import {
  StyledAutocomplete,
  StyledErrorMessage,
  StyledFormButtonsWrapper,
  StyledFormHeader,
  StyledFormHeading,
  StyledFormModal,
  StyledLoadingContainer,
  StyledSelect,
  StyledTimeSlotText,
  StyledXButton,
} from "./ReservationModal.styles";
import { StyledCalendarFullButton } from "../Calendar.styles";
import { ReactComponent as Xicon } from "../../../assets/X-ico.svg";
import { useServices } from "../../../services/services";
import { postNewappointment } from "../../../services/appointments/appointments";
import { useSalonFreeSlots } from "../../../services/availableTimeSlots/availableTimeSlots";
import { StyledDatePicker } from "../../dateTimePicker/DateTimePicker.styles";
import { StyledMenuItem } from "../../dropdown/Dropdown.styles";
import { Appointment, ServiceData } from "../../../types/Types";
import {
  Severity,
  useNotificationContext,
} from "../../../context/NotificationContext";
import { InputField } from "../../inputs/TextField";

const defaultFormData = {
  date: dayjs().format("YYYY-MM-DD"),
  ownerName: "",
  petName: "",
  mobilePhone: "",
  note: "",
  serviceIds: null,
  timeData: {
    workerId: 0,
    timeSlot: "default",
  },
};

const defaultFormErrors = {
  ownerNameError: false,
  serviceError: false,
  timeError: false,
  timeErrorMessage: "",
};

interface ReservationModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
}

const ReservationModal = ({
  modalIsOpen,
  setModalIsOpen,
  handleClose,
}: ReservationModalProps) => {
  const [newFormData, setNewFormData] = useState<Appointment>(defaultFormData);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [freeTimeSlots, setFreeTimeSlots] = useState<any[]>([]);
  const [serviceData, setServiceData] = useState<ServiceData[]>([]);
  const { data, isFetching } = useServices();
  const { showNotification } = useNotificationContext();

  useEffect(() => {
    if (data && !isFetching) {
      const newArray = data.map((service) => ({
        label: service.value,
        id: service.key,
      }));
      setServiceData(newArray);
    }

    if (data.length > 0 && !isFetching) {
      setNewFormData((prev) => ({
        ...prev,
        serviceIds: null,
      }));
    }
  }, [data, isFetching]);

  const { mutate: postNewAppointmentMutation } = useMutation(
    postNewappointment,
    {
      onSuccess: (data) => {
        handleClose();
        showNotification("Rezervacija uspesno dodata", Severity.Success);
        
      },
      onError: (error) => {
        showNotification(
          "Greška prilikom postavljanja rezervacije",
          Severity.Error
        );
      },
    }
  );

  let selectedDateDash = dayjs(selectedDate).format("YYYY-MM-DD").toString();
  let selectedDateSlash = dayjs(selectedDate).format("MM/DD/YYYY").toString();

  const {
    data: freeSlotsData,
    isLoading,
    refetch: refetchSlots,
  } = useSalonFreeSlots(selectedDateDash, newFormData.serviceIds);

  useEffect(() => {
    setFreeTimeSlots(freeSlotsData?.result?.[selectedDateSlash]);
  }, [freeSlotsData]);

  const handleDateChange = (field, e) => {
    setNewFormData((prev) => ({
      ...prev,
      date: e.format("YYYY-MM-DD"),
    }));
    setSelectedDate(e);
    if (newFormData.serviceIds && newFormData.serviceIds.length) {
      refetchSlots();
    }
  };

  const handleServiceChange = (e, value: any) => {
    setNewFormData((prev) => ({
      ...prev,
      serviceIds: value.map((i) => i.id),
    }));
    if (newFormData.serviceIds && newFormData.serviceIds.length) {
      refetchSlots();
    }
  };

  const handleChange = (field: string, value: any) => {
    setFormErrors((prev) => ({ ...prev, [field]: false }));
    setNewFormData((prev) => ({ ...prev, [field]: value }));

    if (field === "ownerName") {
      setNewFormData((prev) => ({
        ...prev,
        "ownerName": value,
      }));
    }

    if (field === "startTime") {
      const timeDataValue = JSON.parse(value);

      setNewFormData((prev) => ({
        ...prev,
        timeData: {
          workerId: timeDataValue.workerId as number,
          timeSlot: timeDataValue.timeSlot as string,
        },
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {
      ownerNameError: false,
      serviceError: false,
      timeError: false,
      timeErrorMessage: "",
    };

    if (!newFormData.ownerName.trim()) {
      newErrors.ownerNameError = true;
    }

    if (!newFormData.serviceIds || !newFormData.serviceIds.length) {
      newErrors.serviceError = true;
    }

    if (newFormData.timeData.timeSlot === "default") {
      newErrors.timeError = true;
      newErrors.timeErrorMessage = "Molimo vas odaberite termin";
    } else if (
      newFormData.date === dayjs().format("YYYY-MM-DD") &&
      dayjs().hour() >= Number(newFormData.timeData.timeSlot.slice(0, 2)) &&
      dayjs().minute() > Number(newFormData.timeData.timeSlot.slice(3, 5))
    ) {
      newErrors.timeError = true;
      newErrors.timeErrorMessage = "Termin ne moze biti u proslosti";
    }

    if (!newFormData.serviceIds) {
      newErrors.serviceError = true;
    }

    setFormErrors(newErrors);
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const errors = validateForm();

    if (Object.values(errors).every((error) => !error)) {
      postNewAppointmentMutation(newFormData);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewFormData({...defaultFormData,date: selectedDate.format("YYYY-MM-DD") });
    setFormErrors(defaultFormErrors);
  };

  useEffect(() => {
    if (
      selectedDate &&
      newFormData.serviceIds &&
      newFormData?.serviceIds[0] !== null
    ) {
      refetchSlots();
    }
  }, [newFormData.serviceIds]);

  return (
    <UniversalModal
      isModalOpen={modalIsOpen}
      handleClose={closeModal}
      width={
        window.innerWidth >= 900
          ? "34.375rem"
          : window.innerWidth >= 600
          ? "65%"
          : "90%"
      }
    >
      <StyledFormModal>
        <StyledFormHeader>
          <StyledFormHeading>Ručno Zakazivanje</StyledFormHeading>
          <StyledXButton onClick={closeModal}>
            <Xicon />
          </StyledXButton>
        </StyledFormHeader>
        <form onSubmit={handleSubmit}>
          {formErrors.ownerNameError && (
            <StyledErrorMessage>Molim vas unesite ime</StyledErrorMessage>
          )}
          <InputField
            label="Ime vlasnika"
            value={newFormData.ownerName}
            onChange={(e) => handleChange("ownerName", e.target.value)}
          />
          <StyledDatePicker
            value={selectedDate}
            minDate={dayjs()}
            onChange={(e) => handleDateChange("date", e)}
          />
          {formErrors.serviceError && (
            <StyledErrorMessage>Molimo odaberite uslugu</StyledErrorMessage>
          )}
          {serviceData?.length > 0 && (
            <StyledAutocomplete
              multiple
              options={serviceData || []}
              disablePortal
              value={newFormData?.serviceIds?.[0]}
              onChange={(e: any, newValue) => handleServiceChange(e, newValue)}
              renderInput={(params) => (
                <TextField {...params} label={"Odaberite uslugu"} />
              )}
            />
          )}
          {formErrors.timeError && (
            <StyledErrorMessage>
              {formErrors.timeErrorMessage}
            </StyledErrorMessage>
          )}
          {isLoading ? (
            <StyledLoadingContainer>Loading...</StyledLoadingContainer>
          ) : (
            <StyledSelect
              value={JSON.stringify(newFormData.timeData)}
              onChange={(e) => handleChange("startTime", e.target.value)}
            >
              {freeTimeSlots?.length && (
                <StyledMenuItem
                  value={JSON.stringify({ workerId: 0, timeSlot: "default" })}
                  disabled
                >
                  Odaberite Termin
                </StyledMenuItem>
              )}
              {freeTimeSlots?.length ? (
                freeTimeSlots?.map((slot) => (
                  <StyledMenuItem
                    key={slot?.timeSlot}
                    value={JSON.stringify(slot)}
                  >
                    <StyledTimeSlotText>{slot?.timeSlot}</StyledTimeSlotText>
                  </StyledMenuItem>
                ))
              ) : (
                <StyledMenuItem
                  value={JSON.stringify({ workerId: 0, timeSlot: "default" })}
                  disabled
                >
                  <StyledTimeSlotText>
                    Nema slobodnih termina
                  </StyledTimeSlotText>
                </StyledMenuItem>
              )}
            </StyledSelect>
          )}
          <InputField
            label="Ime ljubimca"
            value={newFormData.petName}
            onChange={(e) => handleChange("petName", e.target.value)}
          />
          <InputField
            label="Telefon"
            type="number"
            value={newFormData.mobilePhone}
            onChange={(e) =>
              handleChange("mobilePhone", e.target.value.toString())
            }
          />
          <InputField
            label="Napomena"
            multiline
            minRows={window.innerWidth <= 600 ? 3 : 4}
            value={newFormData.note}
            onChange={(e) => handleChange("note", e.target.value)}
          />
          <StyledFormButtonsWrapper>
            <StyledCalendarFullButton type="submit">
              Sačuvaj
            </StyledCalendarFullButton>
          </StyledFormButtonsWrapper>
        </form>
      </StyledFormModal>
    </UniversalModal>
  );
};

export default ReservationModal;
