import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from 'react-i18next';

import UniversalModal from "../../modal/UniversalModal";
import {
  StyledCalendarEmptyButton,
  StyledCalendarFullButton,
} from "../Calendar.styles";
import {
  StyledCalendarCancelButton,
  StyledDescriptionContainer,
  StyledEventActionWrapper,
  StyledEventDescription,
  StyledEventDescriptionLabel,
  StyledEventLabel,
  StyledEventModalWrapper,
  StyledHeaderContainer,
  StyledHeaderTitle,
  StyledeEventContentWrapper,
} from "./EventModal.styles";
import { ReactComponent as Xicon } from "../../../assets/X-ico.svg";
import { useMutation } from "react-query";
import { postNotificationStatus } from "../../../services/notifyAboutStatus/notifyAboutStatus";
import {
  Severity,
  useNotificationContext,
} from "../../../context/NotificationContext";
import { StyledXButton } from "../reservationModal/ReservationModal.styles";
import { cancelAppointment } from "../../../services/appointments/appointments";
import dayjs from "dayjs";

const checkOneHourAfterEnd = (endTime) => {
  const oneHourAfterEnd = dayjs(endTime).add(1, "hour");
  return dayjs().isAfter(oneHourAfterEnd);
};
interface EventModalProps {
  modalIsOpen: boolean;
  setNotifyClientModalIsOpen: Dispatch<SetStateAction<boolean>>;
  eventData: any;
  handleClose: () => void;
}

const EventModal = ({
  modalIsOpen,
  setNotifyClientModalIsOpen,
  eventData,
  handleClose,
}: EventModalProps) => {
  const today = dayjs();
  const { showNotification } = useNotificationContext();
  const { t } = useTranslation();

  const { mutate: postNotificationStatusMutation } = useMutation(
    postNotificationStatus,
    {
      onSuccess: (data) => {
        showNotification("Notifikacija uspesno poslata", Severity.Success);
      },
      onError: (error) => {
        showNotification("Greška prilikom slanja notifikacije", Severity.Error);
      },
    }
  );

  const { mutate: cancelAppointmentMutation } = useMutation(cancelAppointment, {
    onSuccess: (data) => {
      handleClose();
      showNotification("Uspesno otkazan termin.", Severity.Success);
    },
    onError: (error) => {
      showNotification("Greška prilikom otkazivanja termina", Severity.Error);
    },
  });

  const handleFinished = () => {
    postNotificationStatusMutation({
      appointmentId: eventData.appointmentId,
      bodyText: "Tretman vašeg ljubimca je završen, možete doći",
    });
    handleClose();
  };

  if (!modalIsOpen || !eventData) {
    return null;
  }

  const openNotifyClient = () => {
    setNotifyClientModalIsOpen(true);
    handleClose();
  };

  return (
    <UniversalModal
      isModalOpen={modalIsOpen}
      handleClose={handleClose}
      width={
        window.innerWidth >= 900
          ? "34rem"
          : window.innerWidth >= 600
          ? "60%"
          : "90%"
      }
    >
      <StyledEventModalWrapper>
        <StyledHeaderContainer>
          <StyledHeaderTitle>Pregled zakazanog termina</StyledHeaderTitle>
          <StyledXButton onClick={handleClose}>
            <Xicon />
          </StyledXButton>
        </StyledHeaderContainer>
        <StyledeEventContentWrapper>
          <StyledEventLabel>{`Vlasnik: ${eventData?.ownerName}`}</StyledEventLabel>
          {eventData?.mobilePhone?.length > 0 && (
            <StyledEventLabel>{`Telefon: ${eventData?.mobilePhone}`}</StyledEventLabel>
          )}
          {eventData?.services.map((service) => (
            <StyledEventLabel
              key={service.serviceGroupId}
            >{`Usluga: ${service.serviceName}`}</StyledEventLabel>
          ))}
          {eventData?.petName?.length > 0 && (
            <StyledEventLabel>{`Ime Ljubimca: ${eventData?.petName}`}</StyledEventLabel>
          )}
          {eventData?.petBreed && (
            <StyledEventLabel>{`Rasa i težina: ${t('breeds.'+eventData?.petBreed)} ${eventData?.petWeight}kg`}</StyledEventLabel>
          )}
          {eventData?.note && (
            <StyledDescriptionContainer>
              <StyledEventDescriptionLabel>
                {`Napomena: `}
              </StyledEventDescriptionLabel>
              <StyledEventDescription>{eventData?.note}</StyledEventDescription>
            </StyledDescriptionContainer>
          )}
        </StyledeEventContentWrapper>
        {!eventData.isManualReservation &&
          !checkOneHourAfterEnd(eventData?.end) && (
            <StyledEventActionWrapper>
              <StyledCalendarCancelButton onClick={handleFinished}>
                Završeno!
              </StyledCalendarCancelButton>
              <StyledCalendarFullButton onClick={openNotifyClient}>
                Obavesti Klijenta
              </StyledCalendarFullButton>
            </StyledEventActionWrapper>
          )}
        {!checkOneHourAfterEnd(eventData?.end) && (
          <StyledCalendarEmptyButton
            onClick={() => cancelAppointmentMutation(eventData?.appointmentId)}
            sx={{ width: "100%" }}
          >
            Otkazi Termin
          </StyledCalendarEmptyButton>
        )}
      </StyledEventModalWrapper>
    </UniversalModal>
  );
};

export default EventModal;
