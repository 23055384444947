import React, { useState, useEffect, useCallback } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AlertColor } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Home from "./pages/Home";
import { Login } from "./pages/login/Login";
import {
  ANALYTICS,
  CALENDAR,
  DAYS_OFF,
  HELP,
  LOGIN,
  PRIVACY_POLICY,
  PROFILE_SALON,
  REGISTRATION,
  REGISTRATION_VERIFICATION,
  SERVICES,
  STAFF,
  STEP_1,
  STEP_2,
  STEP_3,
  STEP_4,
  SUCCESS,
  SUPPORT,
} from "./constants/urlPaths";
import { LocaleContext, PortalLocale } from "./context/LocaleContext";
import findSupportedLocale from "./public/findSupportedLocale";
import i18n from "./i18n";
import { RegistrationEmail } from "./pages/registration/RegistrationEmail";
import { RegistrationPassword } from "./pages/registration/RegistrationPassword";
import { RegistrationSuccess } from "./pages/registration/RegistrationSuccess";
import CalendarPage from "./pages/calendarPage/CalendarPage";
import { NotificationContext, Severity } from "./context/NotificationContext";
import Notification from "./components/notification/Notification";
import { Step1 } from "./pages/onBoarding/Step1";
import Step2 from "./pages/onBoarding/Step2";
import { Step3 } from "./pages/onBoarding/Step3";
import { Step4 } from "./pages/onBoarding/Step4";
import { SalonProfile } from "./pages/profile/SalonProfile";
import Staff from "./pages/staff/Staff";
import Services from "./pages/services/Services";
import { ProtectedOnboardRoute } from "./components/route/ProtectedOnboardRoute";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/sr";
import { ProtectedSalonRoute } from "./components/route/ProtectedSalonRoute";
import DaysOff from "./pages/daysOff/DaysOff";
import { PrivacyPolicy } from "./pages/privacyPolicyPage/PrivacyPolicy";
import { Page404 } from "./pages/404Page/404Page";
import { AnalyticsPage } from "./pages/analyticsPage/analyticsPage";
import { SupportPage } from "./pages/supportPage/SupportPage";
import { SupportPageProtected } from "./pages/supportPage/SupportPageProtected";

const queryClient = new QueryClient();
interface IeNavigator extends Navigator {
  userLanguage?: string;
  browserLanguage?: string;
}

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Poppins",
      textTransform: "none",
      fontSize: "1rem",
      color: "#7B7B7B",
    },
  },
});

const App = () => {
  const [locale, setLocale] = useState<PortalLocale>("sr-SR");
  const [, setLocaleInitialized] = useState(false);
  const [notification, setNotification] = useState<{
    message: string;
    color: AlertColor;
  } | null>(null);

  useEffect(() => {
    const ieNavigator: IeNavigator = window.navigator;
    const { userLanguage, browserLanguage } = ieNavigator;

    const navigatorLanguages = window.navigator.languages as
      | readonly string[]
      | undefined;

    const navigatorLanguage = window.navigator.language as string | undefined;

    const uniqueUserLanguages = new Set([
      ...(navigatorLanguages ?? []),
      navigatorLanguage ?? "",
      userLanguage ?? "",
      browserLanguage ?? "",
    ]);

    const userLanguages = Array.from(uniqueUserLanguages).filter((language) =>
      Boolean(language)
    );

    const portalLocale: PortalLocale = "sr-SR";

    //  const portalLocale: PortalLocale = findSupportedLocale(userLanguages) ?? "en-GB";

    i18n.changeLanguage(portalLocale).then(() => setLocaleInitialized(true));

    setLocale(portalLocale);
  }, []);

  const updateLocale = useCallback(
    async (value: PortalLocale): Promise<void> => {
      await i18n.changeLanguage(value);
      setLocale(value);
    },
    []
  );

  const showNotification = (message: string, severity?: Severity): void => {
    const color = (() => {
      switch (severity) {
        case Severity.Info:
          return "info";
        case Severity.Success:
          return "success";
        case Severity.Error:
          return "error";
        default:
          throw new Error("Unsupported severity");
      }
    })();

    setNotification({
      message,
      color,
    });
  };

  const notificationElement = (() => {
    if (notification === null) {
      return null;
    }

    return (
      <Notification
        severity={notification.color}
        onClose={() => setNotification(null)}
      >
        {notification.message}
      </Notification>
    );
  })();

  return (
    <QueryClientProvider client={queryClient}>
      <LocaleContext.Provider
        value={{
          locale,
          updateLocale,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"sr"}>
          <ThemeProvider theme={theme}>
            <NotificationContext.Provider value={{ showNotification }}>
              {notificationElement}
              <Routes>
                <Route path="*" element={<Page404 />} />
                <Route index path={CALENDAR} element={<CalendarPage />} />
                <Route path="/" element={<Navigate to={CALENDAR} />} />
                <Route
                  path={SERVICES}
                  element={
                    <ProtectedSalonRoute>
                      <Services />
                    </ProtectedSalonRoute>
                  }
                />
                <Route
                  path={ANALYTICS}
                  element={
                    <ProtectedSalonRoute>
                      <AnalyticsPage />
                    </ProtectedSalonRoute>
                  }
                />
                <Route
                  path={STAFF}
                  element={
                    <ProtectedSalonRoute>
                      <Staff />
                    </ProtectedSalonRoute>
                  }
                />
                <Route path={HELP} element={<SupportPage />} />
                <Route path={SUPPORT} element={<SupportPageProtected />} />
                <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
                <Route path={DAYS_OFF} element={<DaysOff />} />
                <Route path={LOGIN} element={<Login />} />
                <Route path={REGISTRATION} element={<RegistrationEmail />} />
                <Route
                  path={PROFILE_SALON}
                  element={
                    <ProtectedSalonRoute>
                      <SalonProfile />
                    </ProtectedSalonRoute>
                  }
                />
                <Route
                  path={REGISTRATION_VERIFICATION}
                  element={<RegistrationPassword />}
                />
                <Route path={SUCCESS} element={<RegistrationSuccess />} />
                <Route
                  path={STEP_1}
                  element={
                    <ProtectedOnboardRoute>
                      <Step1 />
                    </ProtectedOnboardRoute>
                  }
                />
                <Route
                  path={STEP_2}
                  element={
                    <ProtectedOnboardRoute>
                      <Step2 />
                    </ProtectedOnboardRoute>
                  }
                />
                <Route
                  path={STEP_3}
                  element={
                    <ProtectedOnboardRoute>
                      <Step3 />
                    </ProtectedOnboardRoute>
                  }
                />
                <Route
                  path={STEP_4}
                  element={
                    <ProtectedOnboardRoute>
                      <Step4 />
                    </ProtectedOnboardRoute>
                  }
                />

                <Route path={STAFF} element={<Home />} />
              </Routes>
            </NotificationContext.Provider>
          </ThemeProvider>
        </LocalizationProvider>
      </LocaleContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
