import { Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { useQueryClient } from "react-query";

import {
  StyledHideMenuButton,
  StyledMenuButton,
} from "./NavigationMenu.styles";
import { COLORS } from "../../constants/colors";
import ReservationModal from "../calendar/reservationModal/ReservationModal";
import { USE_REFETCH_APPOITMENTS_KEY } from "../../constants/queryKeys";
import { useDateRangeContext } from "../../context/DateRangeContext";

interface ToolBarMenuProps {
  closed: boolean;
  openMenu: () => void;
}

const ToolbarMenu = ({ closed, openMenu }: ToolBarMenuProps) => {
  const { dateRange } = useDateRangeContext();
  const [formModalIsOpen, setFormModalIsOpen] = useState<boolean>(false);
  const queryclient = useQueryClient();
  const handleRefetch = () =>{
    setFormModalIsOpen(false);
    queryclient.invalidateQueries({ queryKey: `appoitment-${dateRange.startDate}-${dateRange.endDate}` || USE_REFETCH_APPOITMENTS_KEY });
  } 

  return (
    <>
      <Toolbar style={{ justifyContent: "space-between" }}>
        {closed ? (
          <>
            <StyledMenuButton
              aria-label="open drawer"
              onClick={() => openMenu()}
            >
              <MenuIcon style={{ color: COLORS.gray_500 }} />
            </StyledMenuButton>
            <StyledMenuButton
              onClick={() => setFormModalIsOpen(true)}
              buttoncolor={COLORS.primary}
            >
              <AddIcon style={{ color: COLORS.white }} />
            </StyledMenuButton>
          </>
        ) : (
          <StyledHideMenuButton
            color="default"
            aria-label="open drawer"
            onClick={() => openMenu()}
            edge="start"
          >
            <MenuIcon style={{ color: COLORS.gray_500 }} />
          </StyledHideMenuButton>
        )}
      </Toolbar>
      <ReservationModal
        modalIsOpen={formModalIsOpen}
        setModalIsOpen={setFormModalIsOpen}
        handleClose={() => handleRefetch()}
      />
    </>
  );
};
export default ToolbarMenu;
